import { Avatar } from "@mui/material";
import React, { type FC } from "react";

import type { User } from "@/api/app";
import { getNameInitials } from "@/lib/util";

interface IProps {
  user: Pick<User, "name" | "imageUrl">;
}

export const UserAvatar: FC<IProps> = (props) => {
  const { user } = props;

  return user?.imageUrl == null ? (
    <Avatar sx={{ bgcolor: "var(--primary-color)" }}>{getNameInitials(user.name)}</Avatar>
  ) : (
    <Avatar alt={user.name} src={user.imageUrl} />
  );
};

"use client";

import { createTheme, type Shadows } from "@mui/material";
import { Poppins } from "next/font/google";

const poppins = Poppins({ weight: ["100", "200", "300", "400", "500", "600", "700"], subsets: ["latin"], display: "swap" });

/**
 * Creates an array of shadows for different elevations.
 * @returns An array of shadows.
 */
const createShadows = (): Shadows => {
  const MAX_ELEVATION = 24;
  const elevations = ["none", "var(--elevation-1)", "var(--elevation-2)", "var(--elevation-3)", "var(--elevation-4)", "var(--elevation-5)"];

  return [...elevations, ...Array.from<string>({ length: MAX_ELEVATION - elevations.length }).fill("none")] as Shadows;
};

/**
 * The theme object that defines the visual styling for the application.
 */
export const theme = createTheme({
  palette: {
    background: {
      default: "rgba(255, 248, 247, 1)",
    },
    primary: {
      main: "rgb(182, 35, 33)",
      light: "rgba(71, 71, 71, 0.96)",
      dark: "rgba(0, 0, 0, 0.66)",
    },
    error: {
      main: "rgba(147, 0, 10, 1)",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1216,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: poppins.style.fontFamily,
    h1: {
      fontWeight: 300,
      fontSize: 36,
      lineHeight: "44px",
      color: "var(--text-high-emphasis)",
    },
    h2: {
      lineHeight: "44px",
      fontSize: 36,
      fontWeight: 300,
      color: "var(--text-medium-emphasis)",
    },
    h3: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: "28px",
      letterSpacing: "0.1px",
    },
    h4: {
      fontSize: 18,
      fontWeight: 300,
      letterSpacing: "0.1px",
      lineHeight: "24px",
      fontStyle: "normal",
    },
    body1: {
      fontSize: 14,
      fontFamily: poppins.style.fontFamily,
    },
    body2: {
      fontSize: 12,
      fontFamily: "inherit",
    },
    d2: {
      fontSize: 54,
      lineHeight: "64px",
      fontWeight: 300,
      letterSpacing: "-0.5px",
    },
    d1: {
      fontSize: 36,
      lineHeight: "56px",
      fontWeight: 300,
      letterSpacing: "-0.5px",
    },
    bodylg: {
      fontSize: 18,
      lineHeight: "28px",
      letterSpacing: "0.1px",
      fontWeight: 500,
    },
    bodyrg: {
      fontSize: 15,
      lineHeight: "24px",
      letterSpacing: "0.25px",
      fontWeight: 500,
    },
    bodysm: {
      fontSize: 12,
      lineHeight: "20px",
      letterSpacing: "0.25px",
      fontWeight: 500,
    },
    bodyxs: {
      fontSize: 9,
      lineHeight: "16px",
      letterSpacing: "0.25px",
      fontWeight: 500,
    },
    overline: {
      fontSize: 12,
      lineHeight: "20px",
      letterSpacing: "3px",
      fontWeight: 700,
    },
    overlinelg: {
      fontSize: 15,
      lineHeight: "20px",
      letterSpacing: "2px",
      fontWeight: 600,
    },
    labelsm: {
      fontSize: 12,
      lineHeight: "24px",
      letterSpacing: "0.25px",
      fontWeight: 600,
    },
    labellg: {
      fontSize: 18,
      lineHeight: "32px",
      letterSpacing: "0.15px",
      fontWeight: 600,
    },
  },
  shadows: createShadows(),
  components: {
    MuiInputLabel: {
      defaultProps: {
        variant: "standard",
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: "var(--text-medium-emphasis)",
          position: "relative",
          transform: "uppercase",
          letterSpacing: 2,
          lineHeight: "16px",
          fontSize: 12,
          fontWeight: 600,
        },
        asterisk: (props) => ({
          color: `${props.theme.palette.primary.main} !important`,
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "& .MuiSlider-valueLabel": {
            fontWeight: 700,
            fontSize: 9,
            top: "100%",
            borderRadius: Number.MAX_SAFE_INTEGER,
            backgroundColor: "transparent",
            "&::before": {
              display: "none",
            },
            "& *": {
              color: "white",
            },
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          "& .MuiStepConnector-horizontal.Mui-completed .MuiStepConnector-line": {
            borderColor: "var(--primary-color-hover)",
          },
          "& .MuiStepIcon-root": {
            color: "var(--primary-color-disabled)",

            "& .MuiStepIcon-text": {
              fill: "var(--text-medium-emphasis)",
              fontWeight: 600,
            },
          },
          "& .MuiStepIcon-root.Mui-active": {
            color: "var(--primary-color)",

            "& .MuiStepIcon-text": {
              fill: "white",
              fontWeight: 600,
            },
          },
          "& .Mui-completed": {
            color: "var(--primary-color)",
          },
          "& .MuiStepConnector-line": {
            borderColor: "var(--primary-color-disabled)",
            borderTopWidth: 4,
            borderRadius: 2,
          },
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        minRows: 3,
        maxRows: 5,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          width: ownerState.fullWidth ? undefined : 450,
          borderRadius: 20,
          ...(ownerState.multiline
            ? {
                alignItems: "flex-start",

                "& .MuiIconButton-root": {
                  marginTop: ownerState.size === "medium" ? 12 : 8,
                },
              }
            : {}),

          "& .MuiSvgIcon-root": {
            fontSize: ownerState.size === "medium" ? 24 : 20,
          },
        }),
        input: ({ ownerState }) => ({
          color: "var(--text-high-emphasis)",
          padding: "0 14px",
          ...(ownerState.multiline
            ? {
                marginTop: ownerState.size === "medium" ? 12 : 8,
                marginBottom: ownerState.size === "medium" ? 12 : 8,
              }
            : {
                marginTop: ownerState.size === "medium" ? 12 : 8,
                marginBottom: ownerState.size === "medium" ? 12 : 8,
              }),
          marginRight: 8,
          fontWeight: 600,
          "&::placeholder": {
            color: "grey",
            opacity: 1,
          },
          ...(ownerState.size === "medium" ? { fontSize: 18 } : { fontSize: 14 }),
        }),
      },
      variants: [
        {
          props: { color: "primary" },
          style: {
            border: `2px solid var(--text-high-emphasis)`,
          },
        },
        {
          props: { error: true },
          style: (props) => ({
            borderColor: props.theme.palette.error.main,
          }),
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          "& .MuiFormLabel-root": {
            marginLeft: 18,
            lineHeight: 2,
            textTransform: "uppercase",
            fontSize: 9,
            fontWeight: 600,
            letterSpacing: 2,
            color: "var(--text-medium-emphasis)",

            "&.Mui-disabled": {
              color: "var(--text-medium-emphasis)",
            },
          },
          "& .MuiFormLabel-asterisk": {
            color: "var(--primary-color)",
          },
          "& .MuiFormLabel-colorPrimary": {
            color: "var(--text-medium-emphasis)",

            "&.Mui-focused": {
              color: "var(--text-high-emphasis)",
            },
          },
          "& .MuiInputBase-root": {
            borderColor: "var(--bg-act-p-def)",
            width: ownerState.fullWidth ? "100%" : undefined,
            borderRadius: Number.MAX_SAFE_INTEGER,

            "& input::placeholder": {
              color: "var(--text-low-emphasis)",
            },

            "& fieldset": {
              border: "none",
            },

            "&.MuiInputBase-multiline": {
              borderRadius: "20px",
            },

            "& .MuiSelect-select": {
              padding: "0 1rem",
              borderColor: "transparent",

              "&.Mui-focused": {
                borderColor: "transparent",
                backgroundColor: "transparent",
                outline: "none",
              },
            },
          },
        }),
      },
    },
    MuiTypography: {
      variants: [
        { props: { gutterBottom: true, variant: "h2" }, style: { marginBottom: "16px" } },
        { props: { gutterBottom: true, variant: "h1" }, style: { marginBottom: "20px" } },
      ],

      defaultProps: {
        variantMapping: {
          d1: "h1",
          d2: "h1",
          bodylg: "p",
          bodyrg: "p",
          bodysm: "p",
          bodyxs: "p",
          overline: "p",
          overlinelg: "p",
          labellg: "label",
          labelsm: "label",
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        color: "inherit",
      },
      styleOverrides: {
        sizeMedium: {
          width: 24,
          height: 24,
        },
        sizeSmall: {
          width: 20,
          height: 20,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginLeft: 16,
          fontWeight: 500,
          fontSize: 12,
          letterSpacing: "0.25px",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        focusRipple: false,
        disableRipple: true,
        // checkedIcon: {<CheckboxChecked />}
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          display: "flex !important",
        },
        label: {
          lineHeight: "24px",
          letterSpacing: "0.25px",
          fontWeight: 600,
          fontSize: 12,
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { size: "small" },
          style: {
            borderRadius: "4px",
            padding: "4px 6px",
            "& .MuiChip-labelSmall": {
              padding: 0,
              fontSize: 9,
              fontWeight: 500,
              lineHeight: "16px",
              letterSpacing: "0.25px",
            },
          },
        },
      ],
    },
    MuiPaper: {
      variants: [
        {
          style: {
            borderRadius: 16,
            boxShadow: "0px 3px 10px 0px rgba(17, 17, 17, 0.07), 0px 1px 36px 0px rgba(17, 17, 17, 0.03), 0px 1px 36px 0px rgba(17, 17, 17, 0.03)",
          },
          props: {},
        },
        {
          props: { elevation: 1 },
          style: {
            boxShadow: "var(--elevation-1)",
          },
        },
      ],
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        sizeSmall: {
          borderRadius: 160,
          fontSize: 12,
          padding: "4px 20px",
          textTransform: "none",
          fontWeight: 600,
          lineHeight: "24px",
          letterSpacing: "0.25px",
        },
        sizeMedium: {
          padding: "8px 32px",
          borderRadius: 200,
          letterSpacing: "0.25px",
          lineHeight: "24px",
          fontSize: 12,
          fontWeight: 600,
        },
        sizeLarge: {
          padding: "12px 40px",
          fontSize: 18,
          fontWeight: 600,
          borderRadius: 280,
        },
        root: {
          textTransform: "none",
          boxShadow: "var(--elevation-1)",
          "&:hover": {
            boxShadow: "none",
          },
          "&:disabled": {
            cursor: "not-allowed",
          },
        },
      },
      variants: [
        {
          style: {
            transition: "ease-out background-color 0.15s",
            "&:hover": {
              backgroundColor: "rgb(153 27 27 / 1)",
              transition: "ease-in background-color 0.3s",
            },
            "&:disabled": {
              backgroundColor: "var(--primary-color-disabled)",
              color: "var(--text-invert-medium-emphasis)",
            },
          },
          props: {
            color: "primary",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            boxShadow: "none",
            borderWidth: 2,
            borderColor: "var(--primary-color)",
            transitionDuration: "150ms",
            "&:is(:hover,:focus)": {
              transition: "ease-out background-color 300ms",
              borderWidth: 2,
              color: "var(--primary-color-hover)",
              backgroundColor: "var(--secondary-color-hover)",
            },
            "&:disabled, &.Mui-disabled": {
              borderWidth: 2,
            },
            "&:disabled": {
              color: "unset",
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "ghost" },
          style: {
            padding: 0,
            minWidth: 0,
            boxShadow: "none",
            cursor: "pointer",
            background: "transparent",
            color: "var(--text-high-emphasis)",
            "&:hover": { background: "transparent" },
          },
        },
        {
          props: { variant: "text" },
          style: {
            color: "var(--text-accent)",
            boxShadow: "none",
            padding: 0,
            "&:hover": {
              backgroundColor: "transparent",
              color: "var(--primary-color)",
            },
          },
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "var(--elevation-5)",
          minWidth: 240,
          borderRadius: 8,
          padding: "0.5rem 0.5rem",
        },
        root: {},
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: poppins.style.fontFamily,
          color: "var(--text-medium-emphasis)",
          fontWeight: 600,
          fontSize: 12,
          borderRadius: 8,
          padding: "0.5rem 1.5rem",
          justifyContent: "flex-end",
          "&:hover": {
            backgroundColor: "var(--surface-base)",
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
      variants: [
        {
          props: { selected: true },
          style: {
            backgroundColor: "var(--surface-base)",
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: "1.5rem 2rem",
          boxShadow: "var(--elevation-2)",
          borderRadius: 24,
          color: "var(--text-medium-emphasis)",
        },
      },
    },
    MuiTableBody: {},
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        title: {
          fontSize: 18,
          fontWeight: 300,
          color: "var(--text-high-emphasis)",
          lineHeight: "24px",
          letterSpacing: "0.1px",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          scrollbarColor: "var(--scrollbar-thumb-color) var(--scrollbar-track-color)",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            color: "var(--text-high-emphasis)",
            fontWeight: 700,
            fontSize: 12,
          },
        },
      },
      variants: [{ props: { stickyHeader: true }, style: { "& thead th": { backgroundColor: "white" } } }],
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
          color: "var(--text-medium-emphasis)",
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        autoHideDuration: 5000,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      },
      styleOverrides: {
        root: {
          ".MuiSnackbarContent-root": {
            backgroundColor: "var(--surface-level-5)",
            color: "var(--text-high-emphasis)",
            elevation: "var(--elevation-5)",
            borderRadius: 8,
          },
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: "success" },
          style: {
            backgroundColor: "var(--surface-level-5)",
            color: "var(--mean-success)",
          },
        },
        {
          props: { severity: "info" },
          style: {
            backgroundColor: "var(--surface-level-5)",
            color: "var(--text-medium-emphasis)",
          },
        },
        {
          props: { severity: "error" },
          style: {
            backgroundColor: "var(--surface-level-5)",
            color: "var(--mean-error)",
          },
        },
      ],
    },
  },
});

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    ghost: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    d1: React.CSSProperties;
    d2: React.CSSProperties;
    bodyxs: React.CSSProperties;
    bodysm: React.CSSProperties;
    bodyrg: React.CSSProperties;
    bodylg: React.CSSProperties;
    overline: React.CSSProperties;
    overlinelg: React.CSSProperties;
    labelsm: React.CSSProperties;
    labellg: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    d1?: React.CSSProperties;
    d2?: React.CSSProperties;
    bodyxs: React.CSSProperties;
    bodysm: React.CSSProperties;
    bodyrg: React.CSSProperties;
    bodylg: React.CSSProperties;
    overline: React.CSSProperties;
    overlinelg: React.CSSProperties;
    labelsm: React.CSSProperties;
    labellg: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    d1: true;
    d2: true;
    bodyxs: true;
    bodysm: true;
    bodyrg: true;
    bodylg: true;
    overline: true;
    overlinelg: true;
    labelsm: true;
    labellg: true;
  }
}

"use client";

import { Box, Button, Menu, MenuItem } from "@mui/material";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { type FC, type MouseEventHandler, useState } from "react";

import { logout, type User } from "@/api/app";
import { APP_BASE_PATH } from "@/constants";
import { isAdmin } from "@/lib/util";

import { UserAvatar } from "./UserAvatar";

interface IProps {
  user: User;
}

const menuItems = [
  { label: "Profile", href: "/me" },
  { label: "Billing", href: "/me/billing-and-payments" },
];

export const UserActions: FC<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const pathname = usePathname();

  const handleOnMenuClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleOnMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOnLogout = async () => {
    try {
      await logout();
      handleOnMenuClose();
      window.location.href = APP_BASE_PATH;
    } catch (error) {
      console.error("Failed to logout");
    }
  };

  const { user } = props;

  return (
    <>
      <Button variant="ghost" sx={{ borderRadius: Number.MAX_VALUE }} onClick={handleOnMenuClick}>
        <UserAvatar user={user} />
      </Button>

      <Menu
        open={anchorEl != null}
        anchorOrigin={{ horizontal: "left", vertical: 50 }}
        anchorEl={anchorEl}
        onClose={handleOnMenuClose}
        sx={{ "& li": { padding: 0 }, "& .user-action-item": { display: "inline-block", width: "100%", textAlign: "right", px: 3, py: 1 } }}
      >
        {!isAdmin(user) &&
          menuItems.map((item) => (
            <MenuItem key={item.href} selected={pathname === item.href}>
              <Link href={item.href} className="user-action-item" prefetch onClick={handleOnMenuClose}>
                <Box component="span">{item.label}</Box>
              </Link>
            </MenuItem>
          ))}
        <MenuItem onClick={() => void handleOnLogout()}>
          <span className="user-action-item">Logout</span>
        </MenuItem>
      </Menu>
    </>
  );
};
